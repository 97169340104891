<template>
     <div style="display:inherit"> 
        <vs-tooltip shadow interactivity> 
          <span>sadasd</span>                 
          <template>
            <div class="content-tooltip">
              <div class="body" style="display:flex;flex-direction: column;">
                <div class="text" style="font-size: .8rem;padding: 1px;">
                    <b>Name :</b><span style="font-size: .8rem;padding: 1px;">89</span>
                </div>
                <div class="text" style="font-size: .8rem;padding: 1px;">
                    <b>Email :</b><span style="font-size: .8rem;padding: 1px;">89</span>
                </div>
              </div>
              <footer>
                <div class="text">
                    <b>Course :</b>
                </div>
              </br>
                <div class="text">No Course Found</div>
              </footer>
            </div>
          </template>
        </vs-tooltip>
    </div>
</template>

<script>

import maxios from 'axios';
import {mapActions} from 'vuex';
/*import axios from './shared/jwtInterceptor';
import $ from "jquery";
import socket from "./socket";*/

export default {
  name: 'Tooltip',
  data(){
      return {
        activeTooltip1:true,
      }
  },
  methods:{
      ...mapActions({
            signIn:'auth/login'
        }),
  },
  mounted:function () {
      // body...
  },
}
</script>

<style>
.vs-tooltip.chatapp-tooltip.shadow {
        background: none !important;
        box-shadow: none !important;
        padding: 0px !important;
        border-radius: 0.375rem !important;
    }
    .vs-tooltip.shadow.chatapp-tooltip::before {
        border-radius: 0.375rem !important;
        background: none !important;
        box-shadow: none !important;
        padding: 0px !important;
    }
    .content-tooltip.chatCard.card.general-card {
        background-color: #fff !important;
        border-radius: 0.375rem !important;
    }
    .body.chatCard-header.card-header {
        border-radius: 0px !important;
        border: none !important;
        padding: 1.25rem;
        padding-bottom: 0px !important;
    }
    .chatCard .vs-row.tooltip-text .text {
        color: #6f6b7d !important;
        font-size: 0.9rem !important;
    }
    .chatCard .chatCard-body.card-body {
        padding: 1.25rem;
    }
    .chatCard .chatCard-body.card-body .vs-row.tooltip-text {
        padding: 0px !important;
        border: none !important;
        background-color: transparent !important;
    }
    .chatCard-body .course_list.list-container {
        padding: 0px !important;
        margin-bottom: 0px !important;
    }
</style>
