import axios from "axios";
import store from '../store/index';
import auth from '../store/auth.js';
 
const jwtInterceptor = axios.create({});
 
 
jwtInterceptor.interceptors.request.use((config) => {
    
    const authData = localStorage.getItem('access_token');
    
    if (authData == null) {
      return config;
    }
  
    config.headers.common["Authorization"] = `bearer ${authData}`;
    return config;
  });
  
  jwtInterceptor.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
     
     // if (error.response.status === 401) {
        axios.post('/refreshToken',).then(({data})=>{
                    
                if(data.access_token){
                    
                    var f_name = localStorage.getItem('f_name');
                    

                    localStorage.setItem('access_token',data.access_token);
                    config.headers.common["Authorization"] = `bearer ${data.access_token}`;
                    
                    //window.location =reload();
                }else{
                    this.logout();
                    this.login();
                }
            }).catch(()=>function(){
                //console.log("S");
                this.login();
            });

      //} else {
       // return Promise.reject(error);
      //}
    }
  );
  
  export default jwtInterceptor;