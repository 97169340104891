<template>
    <div id="app">
        <!-- <Home msg="Welcome to Your Vue.js App" ref="form"/> -->
        <div :class="showchatBUtton">
            <div class="container-div" data-id="test" style="opactiy:5">
                <div class="chat-wrap testc">
                    <div class="chat popup-ani" v-if="viewchat"> 
                        <div class="chatwindow" v-if="loginStatus">
                            <chat-window
                                :height="'calc(100vh - 30vh)'"
                                :current-user-id="currentUserId"
                                :rooms="rooms"
                                :rooms-loaded="roomsLoaded"
                                :loading-rooms="loadingRooms"
                                :messages-loaded="messagesLoaded"
                                @fetch-messages="onFetchMessages($event)"
                                @send-message="sendMessage($event)"
                                @open-failed-message="resendMessage($event)"
                                @typing-message="typingMessage($event)"
                                @delete-message="deleteMessage"
                                @edit-message="editMessage"
                                @open-file="openFile"
                                :user-tags-enabled="false"
                                :rooms-order="asc"
                                :messages="messages"
                                :classList="classList"
                                :showAddRoom="false"
                                :loadFirstRoom="false"
                                :show-audio="true"
                                :show-files="true"
                                :scroll-distance="10"
                                :text-messages="{ROOMS_EMPTY:'No User Found',ROOM_EMPTY:'No User Selected Please Reload',IS_TYPING:'typing...'}"
                                :responsive-breakpoint="100000"
                                :show-reaction-emojis="false"
                                :single-room="false"
                                :message-actions="[{name: 'replyMessage',title: 'Reply'},{name: 'editMessage',title: 'Edit Message',onlyMe: true},{name: 'deleteMessage',title: 'Delete Message',onlyMe: true}]"
                            />
                        </div>
                        <div v-else style="height:650px;min-width:360px">
                            <button class="reconnect-chat btn btn-primary" @click="login">Reconnecting...</button>
                        </div>
                    </div>
                    <div class="chat-box"  :style="{'background-color':themecolor}" @click="loadchat()">
                        <span class="messagecount" v-if="this.total_messageCount>0" v-html="this.total_messageCount"></span>
                        <div class="wrap">
                            <div class="img-fab img"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import maxios from 'axios';
    import {mapActions} from 'vuex';
    import axios from './shared/jwtInterceptor';
    import $ from "jquery";
    import socket from "./socket";
    import {tooltip} from './components/Tooltip'
    export default {
        name: 'App',
        data(){
            return {
                role:false,
                activeTooltip1:true,
                asc:"asc",
                showchatBUtton:"hideButton",
                usernameAlreadySelected: false,
                roomlist:true,
                loginStatus:false,
                themecolor:'rgb(115 103 240)',
                total_messageCount:0,
                timer: this,
                counterInterval:null,
                roomId:0,
                count:0,
                viewchat:false,
                direction: 'top',
                fab: false,
                fling: false,
                hover: false,
                tabs: null,
                top: false,
                right: true,
                bottom: true,
                left: false,
                transition: 'slide-y-reverse-transition',
                currentUserId:4321,
                messages:[],
                classList:'content-tooltip',
                rooms : [],
                roomsLoaded: true,
                messagesLoaded: false,
                loadingRooms: true,
                showchat:false,
                active : true,
                isLoading:true,
                auth:{
                    email:"",
                    password:"",
                    token:''
                },
                fetched:false,
                selectedUser:null,
                typingstatus:[],
                m_value: null,
                typing:false,
                timeout:null,
                resend:false,
                mpage:1,
                callfunction:false,
                allcontacts:[],
            }
        },
        components: {tooltip},
        methods:{
            ...mapActions({
                signIn:'auth/login'
            }),
            formatMessages(messages) {
              return messages.map(message => {
                const { content, replyMessage, roomId, to_user, senderId, avatar, username, timestamp, date, files } = message;
                let formattedMessage = `Date: ${date} - Time: ${timestamp}\nFrom: ${username}\nContent: ${content}\n`;
                if (files && files.length > 0) {
                  files.forEach(file => {
                    formattedMessage += `File: ${file.name} (${file.type}, ${file.size} bytes)\nURL: ${file.url}\n`;
                  });
                }
                formattedMessage += `\n`;
                return formattedMessage;
              }).join('\n');
            },
            downloadTextFile(content, fileName) {
              const blob = new Blob([content], { type: 'text/plain' });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = fileName;
              document.body.appendChild(a);
              a.click();
              a.remove();
              window.URL.revokeObjectURL(url);
            },
            onUsernameSelection(username) {
                const sessionID = localStorage.getItem("sessionID");
                if (username) {
                    this.usernameAlreadySelected = true;
                    socket.auth = { username };
                    socket.connect();
                }
            },
            typingMessage(event){
                this.m_value = event.message;
            },
            connectionCreated(){
                socket.on("connect", () => {
                    var me = this.$store.state.auth.user;
                    this.rooms.forEach((user) => {
                        if(me.id == user.roomId){
                            user.self = true;
                            user.connected = true;
                        } 
                    });
                });
                socket.on("disconnect", () => {
                    var me = this.$store.state.auth.user;
                    this.rooms.forEach((user) => {
                        if(me.id == user.roomId){
                            if(user.self){
                                user.connected = false;
                            }
                        }
                    });
                });
                const initReactiveProperties = (user,hasNewMessages = false) => {
                    user.connected = true;
                    user.messages = [];
                    user.hasNewMessages = hasNewMessages;
                };
                socket.on("users", (users) => {
                    if(users.length > 0){
                        users.forEach((user) => {
                            user.self = user.userID === socket.id;
                            initReactiveProperties(user,false);
                            var index = this.rooms.findIndex(m=>m.username == user.username);
                            if(index != '-1'){
                                if(this.rooms[index].users){
                                    this.rooms[index].users[0].status.state = 'online';
                                    this.rooms[index].s_mid = user.userID;
                                    localStorage.setItem('s_mid',user.userID);
                                }
                            }
                        });
                    }
                });
                socket.on("user connected", (user) => {
                    initReactiveProperties(user,false);
                    var index = this.rooms.findIndex(m=>m.username == user.username);
                    if(index != '-1'){
                        if(this.rooms[index].users){
                            this.rooms[index].users[0].status.state = 'online';
                            this.rooms[index].s_mid = user.userID;
                            localStorage.setItem('s_mid',user.userID);
                        }
                    }
                });
                socket.on("user disconnected", (user) => {
                    var index = this.rooms.findIndex(m=>m.username == user.name);
                    if(index != '-1'){
                        if(this.rooms[index].users){
                            this.rooms[index].users[0].status.state = 'offline'
                        }
                    }
                });
                socket.on("messagedeliverd", (user) => {
                    //console.log('here message read = ', this.messages)
                    var s_user = this.selectedUser;
                    if(s_user != null){
                        let rid = this.selectedUser.roomId;
                        var data = {parti_id:rid,distributeStatus:true};
                        axios.post('update-status-chat',data).then(({data}) => {
                        });
                        this.messages.forEach((msg,index)=>{
                            this.messages[index].distributed = true; 
                        });
                    }
                });
                socket.on("private-message", (user) => {
                    var username = user.from; 
                    var content = user.content; 
                    var s_user = this.selectedUser;
                    /*if(s_user != null && s_user.username == user.name ){
                          if(user.edit == true){
                              var content = user.content;
                              var index = this.messages.findIndex(m=>m._id==content._id);
                              this.messages[index] = content
                              return 0;
                          }
                    }*/
                    for (let i = 0; i < this.rooms.length; i++) {
                        var room = this.rooms[i];
                        if (username == room.username) {
                            if(s_user != null){
                                if(s_user.username == username){
                                    this.messages.push(content);
                                }
                            }
                            if (user.from == room.username) {
                                user.hasNewMessages = true;
                                this.rooms[i].unreadCount = this.rooms[i].unreadCount + 1;
                            }
                            break;
                        }
                    }
                });
                socket.on('error', err => {
                    console.log(error);
                });
                socket.on("messageread", (data)=>{
                    //console.log('here message read = ', this.messages)
                    var s_user = this.selectedUser;
                    if(s_user != null){
                        this.messages.forEach((msg,index)=>{
                            this.messages[index].distributed = true; 
                            this.messages[index].seen = true; 
                        });
                    }
                });
                /*socket.on("message-recived", (data)=>{
                    this.readMessage();
                });*/
                socket.on("recived-message", (user) => {
                    var username = user.name; 
                    var content = user.content;
                    console.log('content = ', content)
                    var s_user = this.selectedUser;
                    // console.log(s_user);
                    // console.log(user);
                    if(s_user != null && s_user.username == user.name ){
                        if(user.edit == true){
                            var content = user.content;
                            var index = this.messages.findIndex(m=>m._id==content._id);
                            //console.log(this.messages[index]);
                            if(index != -1){
                                this.messages[index].content = content.content;
                            }
                            return 0;
                        }
                    }

                    if(this.viewchat){
                      document.getElementById(user.content.senderId).style.backgroundColor = "antiquewhite"
                    }

                    this.total_messageCount = 0;
                    if(s_user != null){
                        if(s_user.username == username){
                            this.readMessage();
                            this.messages.push(content);
                        }
                    }
                    for (let i = 0; i < this.rooms.length; i++) {
                        var room = this.rooms[i];   
                        if (username == room.username) {
                            if (user !== this.selectedUser) {
                                user.hasNewMessages = true;
                                this.rooms[i].unreadCount = this.rooms[i].unreadCount + 1;
                                var from = room.roomId;
                                this.recivedMessage(from);
                            }
                            //break;
                        }
                        this.total_messageCount = this.total_messageCount + this.rooms[i].unreadCount;
                    }
                    var index = this.rooms.findIndex(item => item.username == username);
                    if(index != 0){
                        var sproom = this.rooms[index];
                        sproom.index = 0;
                        this.rooms = this.rooms.filter(item => item.username != username);
                        this.rooms = [sproom,...this.rooms];
                    }

                  // if(s_user != null){
                  //   setTimeout(() => {
                  //     for(var i = 0; i < this.messages.length ; i++){
                  //       if(this.messages[i].is_unread == 1){
                  //         document.getElementById(this.messages[i]._id).style.fontWeight = 'bold'
                  //       }
                  //     }
                  //   },200)
                  // }
                });
                socket.on("user-typing", (user) => {
                    var index = this.rooms.findIndex(m=>m.username == user.name);
                    if(index != '-1'){
                        var id = this.rooms[index].roomId;
                        if(this.rooms[index]){
                            this.rooms[index].typingUsers = [id];
                            this.rooms[index].state =  'online';
                        }
                    }
                });
                socket.on("stop-typing", (user) => {
                    var index = this.rooms.findIndex(m=>m.username == user.name);
                    if(index != '-1'){
                        var id = this.rooms[index].roomId;
                        this.rooms[index].typingUsers = [];
                        //this.rooms[index].users[0].status.state = 'is online'
                    }
                });
                socket.on("connect_error", (err) => {
                    if (err.message === "invalid username") {
                        this.usernameAlreadySelected = false;
                    }
                });
            },
            async login(c_status){
                if(this.$token){
                    localStorage.setItem('c_token',this.$token);
                }
                var queryString = window.location.search;
                var token = null;
                if(queryString){
                    const urlParams = new URLSearchParams(queryString);
                    token = urlParams.get('token')
                }
                if(!token){
                  token = (this.$token) ? this.$token : localStorage.getItem('c_token');
                }

                this.auth.token  = token;
                await maxios.post('/login',this.auth).then(({data})=>{
                    var access_token = data.access_token;
                    localStorage.setItem('access_token',access_token);
                    //this.$$jWttoken = access_token;
                    setTimeout(() => {
                        this.signIn(access_token);
                        this.setLoginStatus();
                        if(c_status){
                            this.showchat = true;
                        }
                    });
                }).catch(()=>{
                    if(c_status){
                        this.loginStatus = false;
                    }
                }).finally(()=>{
                    // window.location = this.$apiLink
                    if(c_status){
                        this.loginStatus = false;
                    }
                });  
            },
            setLoginStatus(){
                if(localStorage.getItem('chatstatus') == 'true' || localStorage.getItem('chatstatus') == true){
                  this.loginStatus = true;
                  if(this.rooms.length <= 0){  
                      this.getUsers("4");
                  }
                }else{
                    this.loginStatus = false;
                }
                return this.loginStatus;
            },
            loadchat(loadStudent=null){
                this.viewchat = !this.viewchat;
                if(!this.viewchat){
                    localStorage.setItem('roomId',null);
                }
                var access_token = localStorage.getItem('access_token');
                var store = this.$store.state.auth;
                if(access_token == '' || access_token == 'undefined'){
                    this.login(true);
                }else if(typeof access_token === 'undefined' || access_token === null){
                    this.login(true);
                }else if(access_token && (typeof store === 'undefined' || store === null)){
                    if(access_token){ 
                        this.signIn(access_token);
                    }
                }
                this.total_messageCount = 0;
                if(this.loadingRooms == false && this.rooms.length == 0){
                    this.getUsers('loadchat');
                }

                if(this.viewchat){
                  //console.log('rooms = ', this.rooms)
                  this.rooms.forEach((user) => {
                    //console.log('roomId = ', user.roomId)
                    if(user.unreadCount > 0 ){
                      setTimeout(() => {
                        document.getElementById(user.roomId).style.backgroundColor = "antiquewhite"
                      },200)
                    }
                  });
                  if(loadStudent){
                      this.onFetchMessagesWrapper(loadStudent)
                  }
                }
            },
            openChat(event) {
              $('.chat-box .wrap').toggleClass("ani");
              $('.chat').toggleClass("open-fab");
              $('.img-fab.img').toggleClass("close-fab");
              this.loadchat(event.detail.user_id);
            },
            onScrollFetchMessage(){
                //console.log("scrolling");
            },
            onFetchMessagesWrapper(loadStudent) {
              setTimeout(() => {
                document.getElementById(loadStudent).click();
              },300)
            },
            onFetchMessages(event) {
              //console.log('Fetch Messages', event);
                this.roomId = event.room.roomId;
                //console.log('room id = ', this.roomId)
                this.mpage = this.mpage + 1;
                var id = this.roomId;
                var me  = this.$store.state.auth.user;
                if(event.room.roomId == 'me'){
                    id = me.id;
                }
                if(typeof event.options == 'undefined'){
                    //console.log("inside");
                    //if(event.options.reset == true){
                        this.fetchMessagesId(id,this.mpage);
                        return 0;
                    //}
                }
                this.messages = [];
                this.mpage = 1;
                this.selectedUser = event.room;
                this.typingstatus = "coachingly"+id;
                var from = this.typingstatus;
                socket.emit('message-read',{from});
                event.room.unreadCount = 0;
                var index = this.rooms.findIndex(m=>m.roomId == id);
                if(typeof index != 'undefined' && this.rooms[index] == id){
                    this.rooms[index].unreadCount = 0;
                }
                this.total_messageCount = 0;
                this.fetched = false;
                this.messagesLoaded = false;
                this.roomlist = false;
                localStorage.setItem('roomId',id);
                localStorage.setItem('s_mid',event.room.s_mid);
                this.fetchMessagesId(id,this.mpage);
                event.room.hasNewMessages = false;
                let _self = this;
                setTimeout(()=> {
                  _self.markMessagesRead(id)
                },0)
            },
            async fetchMessagesId(rid,page = 1) {
                let _self = this;
                if(rid != '' && rid != null && rid != 'null'){
                    axios.get('conversation/'+rid+"?page="+page)
                    .then(({data}) => {      
                        var total = data.length;
                        for(var i = 0; i < total ; i++){
                           this.setMessage(data[i],page);
                        }
                        //this.messagesLoaded = true;
                        if(total == 0){
                            this.messagesLoaded = true;
                        }
                        this.fetched = true;
                        setTimeout(() => {
                          for(var i = 0; i < total ; i++){
                            if(data[i].is_unread == 1){
                              document.getElementById(data[i]._id).style.fontWeight = 'bold'
                            }
                          }
                        },100)




                    });
                }
                //this.readMessage();
            },
            async markMessagesRead(id){
              if(id != '' && id != null && id != 'null'){
                axios.get('conversation/mark-read/'+id)
                    .then(({data}) => {

                    });
              }
            },
            setMessage(msg,page){
                
                var me  = this.$store.state.auth.user;
                var v_msg = [];
                if(msg.content !== null || msg.files !== null){
                    if(msg.files != null){
                        var file = msg.files;
                        var file_total = file.length;
                        for(var j= 0 ; j < file_total ; j++){
                            msg.files[j].type = file[j].type;
                            msg.files[j].audio = true;
                            msg.files[j].url = file[j].url;
                            msg.files[j].preview = file[j].preview;
                        }
                    }
                    if(me.id != msg.senderId){
                        msg.senderId = this.selectedUser.roomId;
                        msg.username = this.selectedUser.username;
                        msg.avatar = this.selectedUser.avatar;
                    }else{
                        msg.senderId = me.id;
                        msg.username = me.user_name;
                        msg.avatar = me.UserAvatar;
                    }  
                    msg.distributed = (msg.is_distributed) ? true : false;
                    msg.seen = (msg.is_unread) ? false : true;
                    if(msg.seen){
                        msg.distributed = true;
                    }
                    // msg.disableActions = (msg.is_unread) ? false : true;
                    if(this.messages == ''){
                        this.messages.push(msg);
                    }else{
                        this.messages.unshift(msg);
                    }
                }
            },
            async resendMessage(text){
                await this.login(false);
                text = text.message;
                this.messageRequest(text,true);
            },
            async sendMessage(text) {
                //console.log('text = ', text);
                if(text == null){
                    return 0;
                }
                var me  = this.$store.state.auth.user;
                if(text.files != null){

                    var file = text.files;
                    var file_total = file.length;
                    for(var j= 0 ; j < file_total ; j++){
                        //console.log('blob =', file[j].blob)
                        text.files[j].type = file[j].type;
                        text.files[j].audio = true;
                        text.files[j].url = file[j].localUrl;
                        text.files[j].preview = file[j].localUrl;

                      //const response = await fetch(file[j].localUrl);
                      //const blob = await response.blob();
                    }
                }
                text.to_user = this.selectedUser.roomId;
                text.senderId = me.id;
                text.avatar = me.UserAvatar;
                text.username = me.user_name;
                text.distributed = false;
                // console.log(this.selectedUser);
                var s_user = this.selectedUser;
                if(s_user != null){
                    var index = this.rooms.findIndex(item => item.username == s_user.username);
                    if(index != 0){
                        var sproom = this.rooms[index];
                        sproom.index = 0;
                        this.rooms = this.rooms.filter(item => item.username != s_user.username);
                        this.rooms =  [sproom,...this.rooms];
                    }
                }
                text.seen = false;
                localStorage.setItem('f_name','send_message');
                localStorage.setItem('message',JSON.stringify(text));
                this.messageRequest(text,false);
            },
            async messageRequest(text,resending){
                // console.log('send txt = ', text)
                // console.log('resending = ', resending)
                var fake_id = null;
                var toName = this.selectedUser;
                if(toName != null){
                    toName = toName.username;
                }
                text._id = this.messages.length * 46;
                if(resending){
                    fake_id = text._id;
                }
                text.saved = true;
                var data = {msg:text};
                var sm = false;
                text.failure = false;
                this.messages.push(data);


              var formData = new FormData();
              formData.append('content', text.content);
              formData.append('replyMessage', text.replyMessage);
              formData.append('roomId', text.roomId);
              formData.append('to_user', text.to_user);
              formData.append('senderId', text.senderId);
              formData.append('avatar', text.avatar);
              formData.append('username', text.username);
              formData.append('distributed', text.distributed);
              formData.append('seen', text.seen);
              formData.append('_id', text._id);
              formData.append('saved', text.saved);
              formData.append('failure', text.failure);

              if (text.files && text.files.length > 0) {
                text.files.forEach((file, index) => {
                  const clientFile = new File([file.blob], file.name, { type: file.type });
                  formData.append(`files[${index}][blob]`, clientFile, file.name);
                  formData.append(`files[${index}][name]`, file.name);
                  formData.append(`files[${index}][size]`, file.size);
                  formData.append(`files[${index}][duration]`, file.duration);
                  formData.append(`files[${index}][type]`, file.type);
                  formData.append(`files[${index}][audio]`, file.audio);
                  formData.append(`files[${index}][localUrl]`, file.localUrl);
                  formData.append(`files[${index}][url]`, file.url);
                  formData.append(`files[${index}][preview]`, file.preview);
                });
              }

              sm = await axios.post('conversation/send',formData,{
                headers: {
                  'Content-Type': `multipart/form-data`,
                }
              }).then(({data})=>{
                    //console.log('response data = ', data)
                    localStorage.removeItem('message');
                    localStorage.removeItem('f_name');
                    data.username = data.toName;
                    data.username = data.toName;
                    if(resending){
                        var index = this.messages.findIndex(m=>m._id==fake_id);
                        this.messages.splice(index, 1);
                    }
                    this.messages.push(data);
                    data.userID = data.roomId;
                    this.resend = false;
                    var content = data;
                    var id = text.senderId;
                    var socketid = localStorage.getItem('s_mid');
                    var toRoom = "coachingly"+text.to_user;
                    sm = true;
                    socket.emit('chat-message', { content, toRoom, id, toName});
                    return sm;
              }).
              catch(()=>function(){

              }).
              finally(()=>function(){

              });

              if(sm != true){
                  if(this.resend){
                      text.failure = true;
                      text._id = this.messages.length * 45;
                      this.messages.push(text);
                  }else{
                      var text = localStorage.getItem('message');
                      text = JSON.parse(text);
                      this.resend = true;
                      setTimeout(function(){
                      //your code here
                          this.sendMessage(text);
                      }.bind(this), 2000);
                  }
              }
            },
            deleteMessage(text){
                //this.messages.slice(text.message.indexId,1);
                var obj_indexId = text.message.indexId;
                var data = {id:text.message._id};
                this.messages = this.messages.filter(function( obj ) {
                    if(obj.indexId == obj_indexId){
                        obj.deleted = true;
                    }
                    return obj;
                });
                axios.post('delete-message',data)
                .then(({data}) => {});
                /*var index = this.messages.findIndex(m=>m._id === text.messageId);
                this.messages[index].deleted = true;*/
            },
            async readMessage(){

                let rid = this.selectedUser.roomId;
                var data = {parti_id:rid};
                var from = 'coachingly'+rid;
                socket.emit('message-read',{from});
                axios.post('update-status-chat',data)
                .then(({data}) => {
                    var index = this.rooms.findIndex(m=>m.roomId == rid);
                    this.total_messageCount = 0;
                    this.rooms[index].unreadCount = 0;
                });
            },
            async recivedMessage(from){
                let rid = null;
                if(this.selectedUser != null){
                    rid = this.selectedUser.roomId;
                }
                socket.emit('message-deliverd',{from});
                var data = {from_user:rid};
                if(rid != null){
                    axios.post('message-recived',data).then(() => {});
                }
            },
            async getUnreadMessage() {
                axios.get('getUnread')
                .then(({data}) => {        
                    var total = data.unreadIds.length; 
                    var count = 0;
                    for(var i=0;i < total ; i++ ){
                        var sender_id = data.unreadIds[i].sender_id;
                        var index = this.rooms.findIndex(m=>m.roomId == sender_id);
                        if(typeof this.rooms[index] != 'undefined'){
                            if(this.rooms[index].roomId == data.unreadIds[i].sender_id){
                                this.rooms[index].unreadCount = data.unreadIds[i].messages_count;
                            }
                        }
                        count = count + data.unreadIds[i].messages_count;
                    }
                    if(count != this.total_messageCount){
                        this.total_messageCount = count;
                    }
                });
            },
            async openchatbox(id){
               // console.log("Chatapp"+id);
            },
            async getUsers(a){
                // console.log(a);
                this.loginStatus = true;
                this.showchat = true;
                this.rooms = [];
                var me = this.$store.state.auth.user;
                var community = localStorage.getItem('community');
                if(typeof community == 'undefined' || community == null || community == '' || community == 'main'){
                    community = 'main';
                }
                if(me.role != 'Client'){
                    community = 'main';
                }
                this.rooms=[];
                var url = 'getCommunityMembers/'+community;
                if(localStorage.getItem('access_token')){
                    axios.get(url)
                    .then(({data}) => {
                        this.loginStatus = true;
                        var contacts = data.result;
                        if(contacts.records){
                            contacts = contacts.records;
                            this.allcontacts = contacts;
                            console.log(this.allcontacts);
                            this.setRooms(contacts);
                            this.loadingRooms = false;
                            var username = this.$store.state.auth.user;
                            var socketud = "coachingly"+username.id;
                            // username = "coachingly"+username.id;
                            this.$emit("input", username.user_name);
                            this.onUsernameSelection(username.user_name);
                            socket.emit('join', socketud);
                        }
                    })
                    .catch(() => {
                        // error.response.status Check status code
                    }).finally(() => {
                        //Perform action in always
                    });
                }
            },
            async setRooms(contacts){
                var me = this.$store.state.auth.user;
                this.rooms = [];
                this.currentUserId = me.id;
                var users = [];
                var total = contacts.length;
                this.total_messageCount = 0;
                var idm = null;
                var mename = me.Full_name+" ("+me.user_name+")";
                // contacts = contacts.sort(function(a, b){return a.is_unread - b.is_unread});
                for(var i = 0 ; i < total; i++){
                    idm = contacts[i].id
                    if(idm == me.id){
                        idm = 'me';
                    }
                    var avatar = (contacts[i].UserAvatar) ? contacts[i].UserAvatar : 'assets/imgs/people.png';
                    var from = contacts[i].id;
                    this.recivedMessage(from);
                    //var formname = contacts[i].name +" ("+contacts[i].user_name+")";
                    var formname = contacts[i].name;

                    var role = '';
                    if(contacts[i].role == 'Client'){
                      role = 'Student'
                    }
                    if(contacts[i].role == 'Sub Coach'){
                      role = 'Coach'
                    }
                    if(contacts[i].role == 'Coach'){
                      role = 'Coach*'
                    }

                    users = {
                        roomId: contacts[i].id,
                        roomName: formname,
                        username: contacts[i].user_name,
                        avatar: avatar,
                        unreadCount: contacts[i].is_unread,
                        index: i,
                        lastMessage: {
                            content: contacts[i].level.title,
                            senderId: contacts[i].id,
                            username: formname,
                            timestamp: role,
                            saved: true,
                            distributed: false,
                            seen: false,
                            new: true,
                        },
                        users: [
                            {
                                _id: idm,
                                username: contacts[i].user_name,
                                avatar: avatar,
                                status: {
                                    state: 'offline',
                                    last_changed: ''
                                }
                            },
                            {
                                _id: me.id,
                                username: me.user_name,
                                avatar: me.UserAvatar,
                                status: {
                                    state: 'online',
                                    last_changed: ''
                                }
                            }
                        ],
                        typingUsers: []
                    };
                    this.total_messageCount = this.total_messageCount  + contacts[i].is_unread;
                    this.rooms.push(users);
                }
                this.connectionCreated();
            },
            editMessage(text){
                var index = this.messages.findIndex(m=>m._id === text.messageId);
                var roomId = localStorage.getItem('roomId');
                var toName = this.selectedUser;
                if(this.messages[index].content != text.newContent){
                    this.messages[index].content = text.newContent;
                    var newmessga = this.messages[index];
                    var data = {id:text.messageId,msg:newmessga,parti_id:text.roomId}
                    var toRoom = "coachingly"+toName.roomId;
                    var id = toName.roomId;
                    var content = newmessga;
                    toName = this.selectedUser.username;
                    socket.emit('edit-message', { content, toRoom, id, toName});
                    axios.post('update-message',data)
                    .then(({data}) => {});
                }
                this.messages[index].content = text.newContent;
            },
            openFile(text){
              console.log(text);
              if(text.file.action === "download"){
                this.downloadFile(text.file.file.url, text.file.file.name)
              }

            },
            async downloadFile(fileUrl,fileName){
              try {
                const response = await fetch(fileUrl);
                if (!response.ok) {
                  throw new Error(`Failed to fetch the file: ${response.statusText}`);
                }
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
                console.log('File downloaded successfully');
              } catch (error) {
                console.error('Error downloading the file:', error);
              }
            },
            async settingDefualt(){
                var access_token = localStorage.getItem('access_token');
                var m_token = this.$token;
                if(this.$module == 'community'){
                    this.loginStatus = true;
                    this.showchat = true;    
                    this.getUsers("1");
                }else if(this.$module == 'coachingly'){
                    this.showchatBUtton = true;
                    if(typeof m_token == 'undefined' || m_token != null){
                        if(access_token  == 'undefined'){
                            this.login(true);
                        }else if(access_token){
                            this.loginStatus = true;
                            this.showchat = true;
                            this.getUsers("1");
                            this.signIn(access_token);
                        }else if(this.$token){
                            if(!access_token){
                                this.login(true);
                            }else{
                                if(this.$store.state.auth){
                                    this.loginStatus = true;
                                    this.showchat = true;
                                    this.getUsers("2");
                                }else{
                                    this.login(true);
                                }
                            }
                        }
                    }else{
                        if(access_token){
                            var app  = this;
                            setTimeout(function(app){
                                //your code here
                                app.loginStatus = true;
                                app.getUsers("3");
                            }.bind(this), 5000);
                        }else{
                            this.getUsers("6");
                        }
                    }
                }else{
                    this.login(true);
                }  
                var me = this.$store.state.auth.user;
                if(!me.id){
                    this.signIn(access_token);
                }
                this.unreadInterval = setInterval(function(){
                    var access_token = localStorage.getItem('access_token');
                    if(access_token){
                        //this.getUnreadMessage();              
                        if(this.rooms.length == 0){
                            this.rooms = [];
                            this.getUsers('interval');
                        }
                    }else{
                        if(this.rooms.length == 0){
                            this.rooms = [];
                            this.getUsers('interval');
                        }
                    }
                    if(this.$module == 'community' && window.location.pathname != '/login'){
                        this.showchatBUtton = "showchatbox"
                    }
                }.bind(this), 5000);
            },
            timeoutFunction(){
                this.typing = false;
                var username = this.typingstatus;
                socket.emit('stopTyping',{username});
            },
            destroyed() {
                socket.off("connect_error");
            },
            downloadChat(id){
              let _self = this;
              if(id != '' && id != null && id != 'null'){
                axios.get('conversation/download/'+id)
                .then(({data}) => {
                  const formattedMessages = this.formatMessages(data);
                  this.downloadTextFile(formattedMessages, 'chat.txt');
                });
              }
            },
            insertDownloadButton(id) {
              const buttonHtml = `
                <button class="chat-download-btn" style="background-color: transparent; border-color: transparent; position: absolute; top: 0; bottom: 0; right: 40px;">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24">
                    <path id="download-icon" d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" style="fill: #fff;"></path>
                  </svg>
                </button>
              `;
              document.querySelector('.vac-info-wrapper').insertAdjacentHTML('afterend', buttonHtml);
              document.querySelector('.chat-download-btn').addEventListener('click', () => {
                this.downloadChat(id);
              });
            }
        },
        created:function(){
            this.settingDefualt();
        },
        watch: {
            m_value(value) {
                var roomName = this.typingstatus;
                if(value){
                    if(this.typing == false) {
                        this.typing = true;
                        var from = this.selectedUser.roomId;
                        socket.emit('typing', {from})
                        this.timeout = setTimeout(function(){this.timeoutFunction()}.bind(this), 2000);
                    } else {
                        clearTimeout(this.timeout);
                        this.timeout = setTimeout(function(){this.timeoutFunction()}.bind(this), 1000);
                    }
                }  
            }
        },
        mounted:function () {
            // body...
            window.addEventListener('openchat', this.openChat)
            var app = this;
            window.setTimeout(function() {
                if(app.$module == 'coachingly' && window.location.pathname != '/login'){
                    app.showchatBUtton = "showchatbox"
                }
                $(".chat-box").on('click',function() {
                    $('.chat-box .wrap').toggleClass("ani");
                    $('.chat').toggleClass("open-fab");
                    $('.img-fab.img').toggleClass("close-fab");
                    if(!app.loginStatus){
                        app.selectedUser = null;
                        app.login();
                    }
                });
                $(document).on('click','.vac-room-item',function () {
                    app.roomlist = false;
                    app.fetched = true;
                    /*if($('.vac-icon-textarea').children('div.gif-btn').length <= 0){
                        $('.vac-icon-textarea').prepend('<div class="gif-btn"><div class="vac-emoji-wrapper"><div class="vac-svg-button"><span class="material-icons-two-tone gif-icon" alt="GIF" title="Gifs"> gif </span></div><!----></div></div>');
                    }*/
                    // $('.vac-rooms-container').css('display','none');
                    // $('.vac-col-messages').css('display','flex');
                    var uid = $(this).attr('id');
                    var index = app.allcontacts.findIndex(m=>m.id==uid);
                    var level  = null;
                    if(index != -1){
                        level = app.allcontacts[index].level;
                    }

                    //console.log('all contacts = ', app.allcontacts)

                    window.setTimeout(function() {$('.vac-toggle-button').removeClass('vac-rotate-icon-init');
                    $('.vac-toggle-button').removeClass('vac-rotate-icon');});
                    if(level != null){
                        $('.vac-info-wrapper').attr('id',uid)
                        $('.vac-info-wrapper').attr('data-id',uid)
                        if(!$('.vac-info-wrapper').hasClass('courselist')){
                            $('.vac-info-wrapper').addClass('courselist');
                        }            
                        $('.vac-info-wrapper').find('.vac-text-ellipsis .vac-room-name').after("<div class='level-chat vac-avatar' style='background-color:inherit !important'><img src='"+level.ImagePath+"' alt='"+level.title+"' title='"+level.title+"'></div>");
                    }

                    // $('.vac-info-wrapper').after('<button class="chat-download-btn" style="background-color: transparent; border-color: transparent; position: absolute; top: 0; bottom: 0; right: 40px; ">\n' +
                    //     '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24">\n' +
                    //     '<path id="download-icon" d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" style="fill: #fff;"></path>\n' +
                    //     '</svg>\n' +
                    //     '</button>')
                  //   const buttonHtml = `
                  //       <button class="chat-download-btn" style="background-color: transparent; border-color: transparent; position: absolute; top: 0; bottom: 0; right: 40px;">
                  //         <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24">
                  //           <path id="download-icon" d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" style="fill: #fff;"></path>
                  //         </svg>
                  //       </button>
                  //   `;
                  //
                  // document.querySelector('.vac-info-wrapper').insertAdjacentHTML('afterend', buttonHtml);
                  // var _self = this;
                  // document.querySelector('.chat-download-btn').addEventListener('click', () => {
                  //   _self.downloadChat();
                  // });
                  console.log('uid = ', uid)
                  app.insertDownloadButton(uid);



                });
                var timer;
                var show = true;
                $(document).on('mouseenter','.vac-room-item',function () {
                    var that = this;
                    appenedCourseList(that,'122px')
                }).on('mouseleave','.vac-room-item',function() {
                    $('.tols').hide();
                    show = false;
                });
                $(document).on('mouseenter','.courselist',function () {
                    var that = this;
                    appenedCourseList(that,'180px')
                }).on('mouseleave','.vac-room-item',function() {
                    $('.tols').hide();
                    show = false;
                });
                function appenedCourseList(that,right){
                    if(localStorage.getItem('access_token')){
                        var user = app.$store.state.auth.user;
                        var role = (user.role == 'Client') ? false : true;
                        if(role){
                            timer = setTimeout(function(){
                                $('.tols').hide();
                                show = true;
                                var id = $(that).attr('id');
                                var index = app.allcontacts.findIndex(m=>m.id==id);
                                var user  = 'Coach';
                                if(index != -1){
                                    user = app.allcontacts[index];
                                }
                                if(user.role == 'Client'){
                                    if(!$(that).attr('tooltips')){
                                        var html = "<div class='tool-heading'><div class='alert alert-warning' role='alert'>Not enrolled in any course.</div></div>";
                                        if(user.courselist.length > 0){
                                            html = '';
                                            var course_list = user.courselist;
                                            var course_length = user.courselist.length
                                            for (var i = 0; i < course_length; i++) {
                                                html = html+'<li class="course-list-item"><span class="course-title" title>'+course_list[i].title+'</span></li>'
                                            }
                                            html = '<div class="tool-heading">Enrolled in</div><div class="course courses-container custom-scroll"><ul class="course_list list-container" style="text-align: start;">'+html+'</ul></div>';
                                        }
                                        html = '<div class="vs-tooltip chatapp-tooltip top shadow tols smtool'+id+'" style="position:fixed;right:'+right+';min-width:235px"><div class="content-tooltip chatCard card general-card"><div class="body chatCard-header card-header"><div class="vs-row tooltip-text" style="justify-content: flex-start; align-items: flex-start; flex-direction: row;margin-top: 0px;"><div class="text userName" style="flex-direction: row;display:flex;padding-left: 0;"><span style="font-size: 16px; margin-right: 5px;" ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style="height:15px;width:15px"><path fill="#6f6b7d" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg></span>'+user.name+' </div><div class="text userEmail" style="flex-direction: row;display:flex;padding-left: 0;"><span style="font-size: 16px; margin-right: 5px;" ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="height:15px;width:15px"><path fill="#6f6b7d" d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg></span>'+user.email+' </div></div></div><footer class="chatCard-body card-body" style="margin-top:0px"><div class="vs-row tooltip-text" style="justify-content: flex-start; align-items: flex-start; flex-direction: row;padding-left:0px;margin-top:15px">'+html+'</div></footer></div></div>';
                                        $(that).append(html);
                                        $(that).attr('data-toggle',"tooltip");
                                        $(that).attr('data-html',true); 
                                        $(that).addClass('tooltips');
                                    }
                                    if(show == true){
                                        $('.smtool'+id).show();
                                    }
                                    $(that).attr('tooltips',true);
                                }
                            }, 2000);
                        }
                    }
                }
                $(document).on('click',function(){
                    $('.tols').hide();
                });
                $(document).on('click','.vac-toggle-button',function () {
                    localStorage.setItem('roomId',null);
                    // $('.vac-rooms-container').css('display','flex');
                    // $('.vac-col-messages').css('display','none');
                    app.roomlist = true;
                    app.fetched = false;
                    app.m_value = null;
                    app.selectedUser = null;
                    window.setTimeout(function() {$('.vac-toggle-button').removeClass('vac-rotate-icon-init');
                    $('.vac-toggle-button').removeClass('vac-rotate-icon');});
                });
                $(document).on('click','#roomTextarea',function(){
                    //app.readMessage();
                });
                $(document).on('click','.creater_box',async function(e){
                    //$('.vac-room-item').removeClass('vac-room-selected');
                    var me = app.$store.state.auth.user;
                    var user = $(this).data('userid');
                    // var index = app.rooms.findIndex(m=>m.roomId == user);
                    var id = user;//app.rooms[index].roomId;
                    // app.onFetchMessages(event);
                    if(me.id != id){
                        if(app.viewchat == false){
                            app.loadchat();
                            app.viewchat = true;
                            $('.chat-box .wrap').toggleClass("ani");
                            $('.img-fab.img').toggleClass("close-fab");
                        }
                        setTimeout(function(){
                            $('.vac-room-item').each(function(){
                                if($(this).attr('id') == id){
                                    // $(this).addClass('vac-room-selected');
                                    $(this).click();
                                }
                            });
                        }, 500);
                        setTimeout(function(){
                            $('.vac-toggle-button').removeClass('vac-rotate-icon-init');
                            $('.vac-toggle-button').removeClass('vac-rotate-icon');
                        }, 650);
                    }
                });
                $(document).on('click','.course_code_menu',function(){
                    app.viewchat = false;
                    localStorage.setItem('roomId',null);
                    $('.chat-box .wrap').removeClass("ani");
                    $('.img-fab.img').removeClass("close-fab");
                    setTimeout(async function(){ 
                        var me = app.$store.state.auth.user;
                        if(me.role == 'Client'){
                            app.getUsers("9");
                        }
                    }, 1000);
                });
                async function login(c_status){
                    var token = localStorage.getItem('c_token');
                    if(localStorage.getItem('access_token')){
                        token = localStorage.getItem('access_token');
                    }
                    var form = new FormData();
                    form.append("token", token);
                    var url = app.$apiLink + login;
                    var settings = {
                        "url": url,
                        "method": "POST",
                        "timeout": 0,
                        "headers": {
                            "Accept": "application/json"
                        },
                        "processData": false,
                        "mimeType": "multipart/form-data",
                        "contentType": false,
                        "data": form
                    };
                    $.ajax(settings).done(function (response) {
                        // console.log("ajax");
                        //console.log(response);
                    });
                }
                $(function(){
                    var access_token = localStorage.getItem('access_token');
                    if(typeof access_token != 'undefined' || access_token != null){
                        $('.container-div').css('opacity','1');
                    }
                }); 
            });
        },
        beforeDestroy() {
          // Clean up event listener to prevent memory leaks
          window.removeEventListener('openchat', this.openChat);
        }
    }
</script>
<style>
    .vs-tooltip.chatapp-tooltip.shadow {
        background: none !important;
        box-shadow: none !important;
        padding: 0px !important;
        border-radius: 0.375rem !important;
    }
    .vs-tooltip.shadow.chatapp-tooltip::before {
        border-radius: 0.375rem !important;
        background: none !important;
        box-shadow: none !important;
        padding: 0px !important;
    }
    .content-tooltip.chatCard.card.general-card {
        background-color: #fff !important;
        border-radius: 0.375rem !important;
    }
    .body.chatCard-header.card-header {
        border-radius: 0px !important;
        border: none !important;
        padding: 1.25rem;
        padding-bottom: 0px !important;
    }
    .chatCard .vs-row.tooltip-text .text {
        color: #6f6b7d !important;
        font-size: 0.9rem !important;
    }
    .chatCard .chatCard-body.card-body {
        padding: 1.25rem;
    }
    .chatCard .chatCard-body.card-body .vs-row.tooltip-text {
        padding: 0px !important;
        border: none !important;
        background-color: transparent !important;
    }
    .chatCard-body .course_list.list-container {
        padding: 0px !important;
        margin-bottom: 0px !important;
    }
    .chat-wrap {
        z-index: 9999 !important;
    }
    .vs-row.tooltip-text {
        padding: 10px;
        text-align: center;
        background: #eef2f5;
        border-left: 2px solid #eef2f5;
        border-bottom: 2px solid #fff;
        cursor: default;
        transition: all .25s ease;
        box-shadow: inset 0 0 0 0 rgb(0 0 0 / 10%);
    }
    .vac-room-list
    {
      padding: 0px !important;
    }
    .vac-chat-container .vac-box-search, .vac-chat-container .vac-room-header, .vac-col-messages .vac-room-header {
        background: #7367f0 !important;
        color: #fff;
    }
    .vac-chat-container .vac-avatar {
        margin-right: 10px;
    }
    .vac-chat-container .vac-text-ellipsis.vac-room-name {
        width: calc(100% - 40px);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;
        text-align: left;
    }
    .vac-chat-container .level-chat.vac-avatar {
        margin-right: 0px !important;
        right: 10px;
    }
    .vac-chat-container .vac-box-search .vac-input {
        border-radius: 0.375rem !important;
        color: #6f6b7d;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #dbdade;
    }
    .vac-chat-container .vac-box-search, .vac-chat-container .vac-col-messages .vac-room-header {
        border-radius: 0.375rem 0.375rem 0 0 !important;
        background: #7367f0 !important;
        box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1) !important;
    }
    .vac-chat-container .vac-rooms-container, .vac-chat-container .vac-col-messages {
        border-radius: 0.375rem !important;
        box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1) !important;
    }
    .vac-room-footer {
        border-radius: 0 0 0.375rem 0.375rem !important;
    }
    .vac-rooms-container .vac-room-item {
        border-radius: 0.375rem !important;
    }
    .vs-tooltip{
        box-shadow: 0px 3px 3px 5px #9c9cb3  !important;
    }
    .content-tooltip .body{
        flex-direction: column;
    }
    .content-tooltip footer{
        flex-direction: column;
        margin-top: -20px;
    }
    .chatCard-body .tooltip-text {
        margin-top: 0px !important;
    }
    .chatCard .tooltip-text .tool-heading {
        color: #6f6b7d !important;
        font-size: 0.90rem !important;
    }
    .chatCard .course-list-item {
        padding: 0px 10px 7px 0px;
        max-width: 270px;
    }
    .chatCard .course-list-item .course-title {
        font-size: 0.9rem !important;
        color: #6f6b7d;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;
    }
    .tooltip-text{
        display: flex;
        flex-direction: column !important;
    }
    .tooltip-text .course{
        max-height: 160px;
        overflow-y: auto;
        width: 100%;
    }
    .tooltip-text .course_list li{
        margin-left: 15px;
        list-style: decimal;
    }
    .vs-row.tooltip-text .text{
        padding-bottom: 1px !important;
        padding-top: 1px !important;
        font-size: 12px !important;
    }
    .tooltip-text .tool-heading{
        display: flex;
        width: 100%;
        font-weight: 700;
    }
    .tols{
        position:fixed;
        text-align: initial;
        background: #fff;
        border-radius: 8px;
        color: #444;
        margin: -82px auto 0;
        font-size: 16px;
        padding: 13px;
        border: 0px solid;
        /* border-color: #444 transparent; */
        content: attr(data-title);
        z-index: 999999999999;
        border-radius: 15px;
        flex-wrap: nowrap;
        box-shadow: 3px 3px 5px rgb(0 0 0 / 40%);
        right: 100px;
    }
    .tols::after {
        content: " ";
        position: absolute;
        left: -15px;
        top: calc(100% - 43%);
        border-top: 15px solid transparent;
        border-right: 15px solid #00000094;
        border-left: none;
        border-bottom: 15px solid transparent;
    }
    .vac-message-wrapper .vac-offset-current{
        margin-left:10% !important;
    }
    .vac-message-wrapper .vac-message-box{
        max-width: 90% !important;
    }
    .hideButton{
        display:none;
    }
    .level-chat{
        position: absolute;
        right: 0;
        top: 10px;
        padding:5px;
    }
    .vac-text-last .vac-icon-check{
        display:none !important;
    }
    .vac-room-container .vac-message-new, .vac-text-ellipsis span{
        color: #8a8383;
    }
    .vac-room-header{
        background: rgb(91 60 196) !important;
        color: white
    }
    .vac-room-name.vac-text-ellipsis{
        color:white !important;
    }
    #vac-icon-toggle {
        fill: #f8f9fa !important;
    }
    .vac-info-wrapper .vac-text-ellipsis .vac-room-name,.vac-info-wrapper .vac-text-ellipsis .vac-room-info{
        color:white !important;
    }
    .vac-format-message-wrapper .vac-text-ellipsis{
        display:flex;
    }
    .vac-room-info.vac-text-ellipsis{
        display:flex;
    }
    .vac-reply-container{
        max-height:100px;
    }
    .emoji, button.emoji{
        font-size:17px !important;
        margin: 5px;
    }
    .vac-format-message-wrapper .vac-format-container{
        display:flex !important;
        overflow-wrap: anywhere;
        text-align:initial;
    }
    .vac-badge-counter{
        min-width: 20px !important;
        height: 20px!important;
    }
    .vac-room-container .vac-state-circle{
        position: absolute;
        margin-left: -22px;
        bottom: 17px;
    }
    .container-div{
        opacity:1;
    }
    .vac-card-window{
        border-radius:15px !important;
        height:600px !important;
    }
    .reconnect-chat{
        margin-top:60%;
        margin-bottom:80%;
    }
    .messagecount{
        position: absolute;
        right: -5px;
        background: #01a301;
        color: white;
        border-radius: 20px;
        padding: 0px 0px;
        top: 0px;
        min-width: 22px;
        height: 22px;
        text-align: center;
        align-items: center;
        padding:3px;
    }
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }
    .vac-rooms-container{
        flex: 1 0 25% important;
        min-width : 410px !important;
        border-radius:15px !important;
    }
    .vac-card-window .vac-chat-container{
        display:block;
    }
    .vac-col-messages{
        width:410px !important;
        border-radius:15px;
    }
    .vac-room-name{
        display:flex;
    }
    .sc-user-input--text{
        text-align: justify;
    }
    .chat.open-fab {
        width:410px;
        opacity: 1;
    }
    .chat.popup-ani {
        -webkit-transition: all .8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transition: all .8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        border-radius: 15px;
        box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
        background : white;
    }
    .chat .img {
        background-image: url("./assets/msg.png");
    }
    .chat-box {
        width: 55px;
        height: 55px;
        background-color: rgb(115 103 240);
        border-radius: 30px;
        float: right;
        box-shadow: 0px 12px 45px rgba(0, 0, 0, .3);
        z-index: 5;
        position: fixed;
        right: 15px;
        bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .chat-box .img-fab {
        height: 30px;
        width: 30px;
        margin: 15px auto;
        background-image: url("./assets/msg.png");
        background-position: -1px -55px;
    }
    .chat-box .wrap {
        transform: rotate(0deg);
        -webkit-transition: all .15s cubic-bezier(0.15, 0.87, 0.45, 1.23);
        transition: all .15s cubic-bezier(0.15, 0.87, 0.45, 1.23);
    }
    .chat-box .ani {
        transform: rotate(45deg);
        -webkit-transition: all .15s cubic-bezier(0.15, 0.87, 0.45, 1.23);
        transition: all .15s cubic-bezier(0.15, 0.87, 0.45, 1.23);
    }
    .chat-box .close-fab {
        background-position: -1px -3px;
        transform: rotate(-45deg);
        float: none;
        /*Bootstrap Overide*/
        opacity: 1;
        /*Bootstrap Overide*/
    }
    .chat-wrap {
        position: fixed;
        right: 25px;
        bottom: 65px;
        z-index: 1000;
    }
    .ba-settings {
        position: absolute;
        top: -25px;
        right: 0px;
        padding: 10px 20px;
        background-color: #555;
        border-radius: 5px;
        color: #fff;
    }
    .vac-room-container .vac-room-name{
        color: #0a0a0a !important;
    }
    .vac-box-search{
        border-radius: 15px 15px 0 0;
        background: #5b3cc4;
    }
    .vac-room-list::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        border-radius: 10px;
        background-color: #f8f7fa;
    }
    .vac-room-list::-webkit-scrollbar-track{
        width: 7px;
        background-color: #f8f7fa;
    }
    .vac-room-list::-webkit-scrollbar-track{
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #f8f7fa;
    }
    .vac-room-list::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        border-radius: 10px;
        background-color: #f8f7fa;
    }
    #messages-list::-webkit-scrollbar{
        width: 7px;
        background-color: #f8f7fa;
    }
    #messages-list::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #7367f0;
    }
    @media only screen and (max-width: 768px){
        .vac-room-header {
            height: 65px !important;
        }
    }
</style>