import { io } from "socket.io-client";

var dev_type = process.env.VUE_APP_Enviroment;

const URL = (dev_type == 'local') ? process.env.VUE_APP_SERVER_Local : process.env.VUE_APP_SERVER_Live ;
//console.log('URL = ', URL);
const socket = io(URL, { autoConnect: false,secure: true });

socket.onAny((event, ...args) => {
  //console.log(event, args);
});

export default socket;
