import axios from 'axios'

axios.defaults.withCredentials = true
var token = localStorage.getItem('access_token');

var dev_type = process.env.VUE_APP_Enviroment;

var apiLink = (dev_type == 'local') ? process.env.VUE_APP_Local_Api : process.env.VUE_APP_Live_Api;
if(localStorage.getItem('base_url')){
    apiLink = localStorage.getItem('base_url')+'/api/chat/';
}
axios.defaults.baseURL = apiLink;

if(token != '') {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}
axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'Accept': 'application/json'
};
axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS,Some';

export default {
    namespaced: true,
    state:{
        authenticated:false,
        user:{}
    },
    getters:{
        authenticated(state){
            return state.authenticated
        },
        user(state){
            return state.user
        }
    },
    mutations:{
        SET_AUTHENTICATED (state, value) {
            state.authenticated = value
        },
        SET_USER (state, value) {
            state.user = value
        }
    },
    actions:{
        
        login({commit},access){
            
            if(typeof access == 'undefined' || access == null){
                access = localStorage.getItem('access_token');
            }

            return axios.get('/user',{
  headers: {
    'Authorization': `Bearer ${access}`,
    'Accept': 'application/json'
  },
}).then(({data})=>{
    
                commit('SET_USER',data)
                commit('SET_AUTHENTICATED',true);
                localStorage.setItem('chatstatus',true);
            }).catch(({data})=>{
               // console.log(data);
                localStorage.setItem('chatstatus',false);
            })
        },
        logout({commit}){
            
            commit('SET_USER',{})
            commit('SET_AUTHENTICATED',false);
            //window.location = this.apiLink
        }
    }
}