import Vue from 'vue'
import App from './App.vue';
import Vuesax from 'vuesax'

import 'vuesax/dist/vuesax.css' //Vuesax styles
import ChatWindow from "vue-advanced-chat";
//import 'material-icons/iconfont/material-icons.css';
import "vue-advanced-chat/dist/vue-advanced-chat.css";
var token = localStorage.getItem('access_token');

Vue.prototype.$token = (document.currentScript.getAttribute('token')) ? document.currentScript.getAttribute('token') : token;
Vue.prototype.$theme = document.currentScript.getAttribute('theme');
Vue.prototype.$module = document.currentScript.getAttribute('module');
Vue.use(Vuesax);
Vue.use(ChatWindow);

var dev_type = process.env.VUE_APP_Enviroment;

Vue.prototype.$jWttoken = "JWT";
Vue.prototype.$vms = this;

let apiLink = null;

if(localStorage.getItem('base_url')){
    apiLink = localStorage.getItem('base_url')+'/api/chat/';
}else{
    apiLink = (dev_type == 'local') ? process.env.VUE_APP_Local_Api : process.env.VUE_APP_Live_Api;
}

Vue.prototype.$apiLink = apiLink;
Vue.config.productionTip = false;


//import router from './router'
import store from './store'

let e = document.getElementById("livechatmodule");

if(typeof e === 'undefined' || e === null)
{
    e = document.createElement('div');
    e.id = "livechatmodule";
    let b = document.getElementsByTagName('body')[0];
    b.appendChild(e);
}

var app = new Vue({
  store,
  render: h => h(App),
}).$mount('#livechatmodule')
